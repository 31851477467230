const width = 96;
const height = 54;

export default function FormBioLogoFull({ title }: { title: string }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-label={title}
    >
      <title>{title}</title>
      <g clipPath='url(#clip0_860_14016)'>
        {/* F */}
        <path
          d='M0 1.31958V25.6732H5.81355V16.3577H15.017V11.5627H5.81355V6.15371H17.9416V1.31958H0Z'
          fill='currentColor'
        />
        {/* R */}
        <path
          d='M61.4864 15.1589C64.4501 14.7735 66.7241 12.5459 66.7241 8.95294C66.7176 4.24619 63.6368 1.30652 57.3613 1.30652H45.6496V25.6732H51.4013V17.2069H56.2812C58.7081 17.2069 59.9411 18.0953 60.133 20.8423C60.325 23.4325 60.634 25.0166 61.1318 25.6732H67.0625C66.2556 24.7847 66.1385 23.6252 65.7937 20.029C65.5237 17.1677 64.0597 15.6783 61.4864 15.1589ZM56.4439 12.6863H51.4013V6.03613H56.4471C59.4889 6.03613 61.0017 7.23486 61.0017 9.40042C61.0017 11.7946 59.5019 12.6863 56.4471 12.6863'
          fill='currentColor'
        />
        {/* M */}
        <path
          d='M87.1057 1.31958L83.0229 17.5956H82.909L78.6342 1.31958H69.5479V25.6732H74.9385V7.69868H75.0914L80.0591 25.6732H85.0659L89.9913 7.69868H90.1475V25.6732H96V1.31958H87.1057Z'
          fill='currentColor'
        />
        {/* B */}
        <path
          d='M12.9577 41.0543C15.0528 40.708 16.5362 39.1533 16.5362 36.9355C16.5362 33.6071 14.3143 31.4546 9.27825 31.4546H0V51.4182H9.85733C14.4607 51.4182 17.0893 49.3899 17.0893 45.9341C17.0893 43.0826 15.3033 41.5311 12.9577 41.0543ZM4.60009 35.2762H8.73171C10.7975 35.2762 11.6108 36.194 11.6108 37.4287C11.6108 38.6013 10.7975 39.5224 8.73171 39.5224H4.60009V35.2762ZM9.1709 47.5379H4.61635V43.148H9.1709C11.2985 43.148 12.268 44.0331 12.268 45.3658C12.268 46.6984 11.292 47.5509 9.20343 47.5509'
          fill='currentColor'
        />
        {/* I */}
        <path
          d='M24.0382 31.4546H19.2819V51.4182H24.0382V31.4546Z'
          fill='currentColor'
        />
        {/* M of TM */}
        <path
          d='M48.6587 32.7546H48.6425L48.3692 31.6179H47.5949V33.4438H48.1122V32.2712H48.1252L48.418 33.4438H48.8116L49.1109 32.2712H49.1272V33.4438H49.6705V31.6179H48.9157L48.6587 32.7546Z'
          fill='currentColor'
        />
        {/* T of TM */}
        <path
          d='M45.6561 32.1013H46.2514V33.4438H46.811V32.1013H47.4193V31.6179H45.6561V32.1013Z'
          fill='currentColor'
        />
        {/* Cell division "O" */}
        <path
          d='M43.3267 33.6234C42.0254 31.9902 40.2133 30.4551 39.6375 28.4691C39.0942 26.6041 39.9628 24.6606 40.7761 22.8903C42.9135 18.1672 44.628 12.7125 42.7509 7.86528C41.8144 5.55092 40.1957 3.57926 38.1117 2.21457C35.2524 0.301953 31.7575 -0.402183 28.3845 0.254788C26.7177 0.598154 25.1429 1.29431 23.7652 2.29685C22.3874 3.2994 21.2384 4.58529 20.3946 6.06881C18.6671 9.20773 18.5175 13.062 19.4544 16.521C20.3913 19.98 22.3368 23.0863 24.5945 25.8692C25.8633 27.4337 27.2784 28.9787 27.8673 30.9091C29.0189 34.6817 26.7286 38.5816 26.3643 42.5077C26.0943 45.3951 26.7579 48.6353 28.9669 50.6474C31.3775 52.8391 35.2391 53.6491 38.4013 53.4009C38.8047 53.3682 39.2113 53.3192 39.6115 53.2506C42.4028 52.777 45.0574 51.3986 46.362 48.7986C47.5689 46.3946 47.7153 43.4974 47.0647 40.9105C46.4498 38.4673 45.2136 36.243 43.7659 34.208C43.626 34.0121 43.4731 33.8161 43.3169 33.6234H43.3267ZM28.892 23.3606C24.7865 20.1368 23.0362 13.8459 24.8222 9.01502C26.6571 4.06983 34.2566 4.14823 36.7942 8.47281C37.6791 9.98838 38.0011 11.7979 37.9979 13.565C37.9979 15.6783 37.5522 19.0785 36.6966 21.0775C35.9906 22.7498 35.1285 24.5202 33.2709 24.8762C32.2093 25.0315 31.1269 24.8109 30.2096 24.2523C29.7455 23.9902 29.3018 23.6932 28.8823 23.3639L28.892 23.3606ZM41.3357 47.1687C38.8925 49.3996 34.7674 49.2233 32.5064 47.3974C29.2239 44.7484 30.4959 39.6987 31.7679 36.3475C32.3795 34.7339 33.4759 33.1726 35.3465 33.0518C37.1097 32.9407 38.6746 34.2146 39.6993 35.5309C41.8855 38.353 44.7386 44.0625 41.3259 47.1687'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
