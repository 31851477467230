import { DEFAULT_MARGIN, DEFAULT_PADDING } from '@/constants';
import { Box } from '@mui/material';
import React from 'react';
import FormBioLogoFull from './FormBioLogoFull';
import FormBioLogoMicro from './FormBioLogoMicro';

const TITLE = 'Link to FormBio Home';
// Formbio logo can only be of the following colors:
enum LogoColors {
  white = 'rgb(255,255,255)',
  black = 'rgb(0,0,0)',
  greenSafe = 'rgb(204,255,51)',
  green = 'rgb(179,250,38)',
}

export default function AppLogo({
  minied = false,
  ...props
}: {
  minied?: boolean;
} & React.ComponentProps<typeof Box>) {
  return (
    <Box
      marginTop={DEFAULT_MARGIN}
      marginBottom={DEFAULT_MARGIN}
      paddingLeft={minied ? 0 : DEFAULT_PADDING}
      display='flex'
      color={LogoColors.white}
      {...props}
    >
      {minied ? (
        <FormBioLogoMicro title={TITLE} />
      ) : (
        <FormBioLogoFull title={TITLE} />
      )}
    </Box>
  );
}
